

































































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';

import './scss/PopupAddCompositeFolder.scss';
import { namespace } from 'vuex-class';
import { IMemberInfo } from '@store/modules/project-team/interfaces/Interfaces';
import { IProject } from '@store/modules/project/Interfaces';
import { ProjectDocumentsActions } from '@store/modules/project-documents/Types';
import { IDocument, IDocumentsState } from '@store/modules/project-documents/Interfaces';

const NSTeam = namespace('storeProjectTeam');
const NSProject = namespace('storeProject');
const NSDocuments = namespace('storeProjectDocuments');

@Component({
    name: 'PopupAddCompositeFolder',
    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BaseComposite: () => import('@components/BaseComposite/BaseComposite.vue'),
        BaseCheckbox: () => import('@components/BaseCheckbox/BaseCheckbox.vue'),
    }
})

export default class PopupAddCompositeFolder extends Vue {
    $refs!: {
        basePopup: HTMLFormElement,
        form: HTMLFormElement,
        textField: HTMLFormElement,
    }

    @NSProject.Getter('projectData') projectData!: IProject;
    @NSDocuments.Getter('documents') documents!: IDocument[];
    @NSTeam.Getter('projectTeam') projectTeam!: IMemberInfo[];
    @NSDocuments.Getter('documentsWithoutRecursion') documentsWithoutRecursion!: IDocument[];
    @NSDocuments.State((state: IDocumentsState) => state.viewingDocument) viewingDocument!: IDocument;
    @NSDocuments.Action(ProjectDocumentsActions.A_ADD_COMPOSITE_FOLDER) addCompositeFolder!: (payload) => Promise<void>

    fields: any = {
        title: '',
        parentFolder: '',
    }
    btnDisabled = false;
    realtimeValidation = false;
    errors = {
        title: false,
        parentFolder: false,
    };
    selectedParent:IDocument|null = null;

    @Watch('selectedParent')
    onChangeSelectedParent() {
        this.fields.parentFolder = this.selectedParent ? this.selectedParent.uuid : '';
    }

    get executorOptions() {
        const output: any[] = [];

        this.projectTeam.forEach((item) => {
            output.push({
                label: item.fullName,
                value: item.uuid,
            });
        })

        return output;
    }

    get projectId() {
        if (this.projectData && this.projectData.id) {
            return this.projectData.id;
        }
        return null;
    }

    get titleForExecutor() {
        if (this.fields.userId) {
            const members = this.projectTeam.filter(item => item.uuid === this.fields.userId);
            if (members[0] && members[0].fullName) {
                return members[0].fullName;
            }
        }
        return 'Выберите исполнителя';
    }

    get errorExists() {
        return Object.values(this.errors).some((item) => item);
    }

    get documentsIsFolder() {
        return this.documentsWithoutRecursion.filter(item => item.isFolder);
    }

    resetParent() {
        this.selectedParent = null;
    }

    childrenIsFolder(work) {
        return work.children.filter(item => item.isFolder);
    }

    openPopup() {
        this.$refs.basePopup.openPopup();
        this.$refs.textField.focusInput();
    }

    closePopup() {
        this.$refs.basePopup.closePopup();
    }

    validateFields() {
        this.errors.title = !this.fields.title;
        this.realtimeValidation = true;
    }

    checkValidation() {
        if (this.realtimeValidation) {
            this.validateFields();
        }
    }

    setSelectedParent(e) {
        this.selectedParent = e;
    }

    onSuccess() {
        let nameFolder = this.fields.title;

        this.validateFields();
        if (!this.errorExists) {
            this.btnDisabled = true;

            this.addCompositeFolder({
                appId: this.projectId,
                name: this.fields.title,
                parentId: this.fields.parentFolder,
            })
                .then(() => {
                    this.closePopup();
                    this.$emit('confirm-add-composite-folder', nameFolder);
                    this.fields = {
                        title: '',
                        parentFolder: null,
                    }
                    this.realtimeValidation = false;
                    this.errors = {
                        title: false,
                        parentFolder: false,
                    };
                });

            this.btnDisabled = false;
        }
    }
}
